.red-color {
  color: #c5221f;
}

.green-color {
  color: #137333;
}

.grey-color {
  color: #9aa0a6;
}

.rectangles-container {
  display: flex;
  margin-top: 60px;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.stock-link:hover .stock-rectangle {
  background-color: #F8F9FA;
  text-decoration: none;
}

.stock-rectangle {
  align-items: center;
  padding: 6px;
  background-color: white;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #dadce0;
  white-space: nowrap;
  width: 200px;
  height: 46px;
  margin-left: 8px;
}

.arrow {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.arrow-red {
  background-color: #ffe8e8;
}

.arrow-green {
  background-color: #e6f4ea;
}

.arrow-grey {
  background-color: #f1f3f4;
}

.stock-info,
.change-info {
  letter-spacing: .025em;
  font-family: Roboto, Arial, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
}

.stock-name {
  color: #202124;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stock-price {
  color: #3c4043;
  font-weight: 400;
  letter-spacing: .025em;
}

.change-percentage {
  min-width: unset;
  font-weight: 500;
  height: 16px;
}

.change-info {
  text-align: left;
}

.change-info-mobile {
  display: none;
}

.arrow-mobile {
  display: none;
  padding: 4px;
  border-radius: 6px;
}

.arrow-mobile, .icon {
  font-size: 14px;
}

.arrow-mobile span {
  font-size: .75rem;
}

.arrow-grey-mobile {
  background-color: #f1f3f4;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .stock-rectangle {
    margin-top: 100px; /* Adjust margin-top for mobile devices */
  }
}

/* Add these styles for the mobile view */
@media (max-width: 575px) {
  .stock-rectangle {
    width: 80px;
    height: 87px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .arrow {
    display: none;
  }

  .change-info {
    display: none;
  }

  .change-info-mobile {
    display: block;
  }

  .arrow-mobile {
    display: flex;
    align-items: center;
  }
}
