@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: Vazir, Arial, sans-serif !important;
    background: #f8f8ff;
}

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030; /* This value should be higher than the z-index of other elements to keep the header on top */
    background-color: #f8f9fa; /* Match the background color of your Navbar */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.brand-color {
    background-color: #03A9F4;
}

.material-shadow {
    /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);*/
    border-bottom: 1px solid #e0e3eb;
}

image-lcp {
    padding: inherit;
}

.m-content {
    min-height: 800px;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    outline: none;
}

*:focus {
    outline: none;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.extra-menu .card {
    overflow: unset !important;
}

.rw-popup-container {
    text-align: right;
}

.ReactVirtualized__Table__headerColumn {
    margin: 0 !important;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, .80);
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media screen and (min-width: 1200px) {
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

/**
  Link
**/

a {
    color: #2098f3;
    text-decoration: none;
    word-wrap: break-word;
    cursor: pointer;
}

.cur-ptr {
    cursor: pointer;
}

a:hover {
    color: #40a9ff;
    text-decoration: none;
}

/**
  Button
**/


.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    /* padding-top: 10px; */
    font-size: 14px;
    border-radius: 4px;
    height: 45px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    position: relative;
    color: rgba(0, 0, 0, .65);
    border: 1px solid #e8e8e8 !important;
    outline: none;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-primary {
    color: #fff;
    background-color: #2098f3;
    border-color: #2098f3;
}

/* .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
  background-color: #40a9ff;
  border-color: #40a9ff;
} */

.btn-success {
    color: #fff;
    background-color: #52c41a;
    border-color: #52c41a;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active {
    background-color: #52c41a;
    border-color: #52c41a;
}


.btn-link {
    border: none;
    height: 34px;
    /* padding: 0 15px; */
}

.btn-link:hover {
    background-color: rgba(158, 158, 158, 0.20);
}


/**
  Form
**/

.form-item {
    margin-bottom: 18px;
}

.form-item .btn {
    cursor: pointer;
}

.form-item label {
    font-size: 0.85em;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, .65);
}

.form-label {
    margin-bottom: 10px;
}

/**
  Or separator
**/

.or-separator {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    position: relative;
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 1em;
}

.or-text {
    position: absolute;
    left: 46%;
    top: 0;
    background: #fff;
    padding: 10px;
    color: rgba(0, 0, 0, .45);
}

html, body {
    height: 100%;
    padding: 0;
    margin: 0;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.rtl {
    direction: rtl;
    text-align: right;
    display: inline-block;
}

.ltr {
    direction: ltr;
    text-align: left;
    display: inline-block;
}

.persian-font {
    font-family: Vazir !important;
}

.Helvetica {
    font-family: HelveticaNeueLight, Arial, sans-serif;
}

.english-font {
    font-family: 'Roboto', Arial, sans-serif;
    direction: ltr;
    color: #000;
}

.english-font-summary-title {
    font-family: 'Roboto', "Helvetica Neue", Arial, sans-serif
}

.english-numbers-size {
    font-family: 'Roboto', Helvetica Neue, Arial, sans-serif;
    direction: ltr;
}

.main-content-padding {
    padding: 0 1rem;
}

.active-button {
    color: #1e88e5 !important;
    border-bottom: 3px solid #1e88e5;
}

.just-ltr {
    direction: ltr;
}

.live-chart-height {
    height: 248px;
}

.new-feature {
    border-top: 3px solid red;
}

.ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-display {
    display: flex;
    flex-direction: row
}

.mt-20px {
    margin-top: 20px;
}

.mt-6px {
    margin-top: 10px;
}

.mt-10px {
    margin-top: 10px;
}

.mb-10px {
    margin-bottom: 10px;
}

.pt-20px {
    padding-top: 20px;
}

.p-20px {
    padding: 20px;
}

.p-10px {
    padding: 10px;
}

.pt-10px {
    padding-top: 10px;
}

.pr-20px {
    padding-right: 20px;
}

.pr-40px {
    padding-right: 40px;
}

.p-10px {
    padding: 10px;
}

.plr-20px {
    padding: 0 20px;
}

.plr-40px {
    padding: 0 40px;
}

.mr-1px {
    margin-right: 1px;
}

.mb-1px {
    margin-bottom: 1px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.chart-top-menu {
    color: #5b636a;
}

#popover-basic {
    max-width: none;
    background-color: rgba(0, 0, 0, .9);
    z-index: 3000;
}

.popover-header {
    text-align: center;
    color: #f5f5f5;
    background-color: #424242 !important;
}

.popover-body {
    color: #fff !important;
    white-space: pre-line;
}

.bordered {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 5px;
}

.left-bordered {
    -webkit-box-shadow: -4px 0 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: -4px 0 5px 0 rgba(0, 0, 0, 0.26);
    box-shadow: -3px 0 5px 0 rgba(0, 0, 0, 0.26), inset -2px 0 0 #fff;
    border-radius: 5px;
}

.dl-images img {
    border-radius: 5px;
}

.payment-call-back {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.payment-box {
    width: 50%;
}

.bordered-radius {
    border-radius: 5px;
}

.low-shadow {
    box-shadow: 0 0 0 1px #ebedef;
}

.breadcrumbs {
    margin: -20px 0;
    font-size: 13px;
    width: 100%;
    position: absolute;
}

.purple-border {
    -webkit-box-shadow: 0 2px 5px 0 rgb(33, 33, 33);
    -moz-box-shadow: 0 2px 5px 0 rgb(33, 33, 33);
    box-shadow: 0 2px 5px 0 rgb(33, 33, 33);
    border-radius: 5px;
}

.middle-box-content {
    width: 70%;
}

a {
    cursor: pointer;
}

.middle-box-content-full {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
}

.baha-link {
    color: #5f6368;
    cursor: pointer;
}

.news-market-active {
    border-bottom: 3px solid #1e88e5;
}

.baha-link a {
    color: #4f4f4f;
    cursor: pointer;
}

.footer-top-border {
    border-top: 1px solid #9e9e9e;
}

.bx-ctn {
    padding: 0 10px;
}

.dl-image {
    width: 500px;
    margin: 20px auto;
}

.dl-box {
    justify-content: center;
    margin: 20px;
}

.dl-box > a {
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 5px 10px;
    margin: 10px;
}

.dl-box > a:hover {
    background-color: #EEE;
    border: 1px solid rgba(0, 0, 0, 1);
}

.dl-text {
    line-height: 80%;
    text-align: right;
}

.dl-text > span {
    font-size: 10px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    display: inline-block;
    margin-bottom: 2px;
}

.dl-text > strong {
    font-size: 12px;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.footer-link a {
    color: #6C757D;
    cursor: pointer;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.home-table-condensed {
    font-size: 12px;
    margin: 0;
}

.nav-item a {
    color: #444;
}

.nav-link {
    padding: .5rem .7rem;
    color: #444;
}

.borderless td, .borderless th {
    border: none;
}

.market-summary {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    /*height: 91px;*/
    overflow: hidden;
    padding-top: 5px;
}

.home-markets-table-title a {
    color: #616161;
}

.market-summary-title {
    font-family: Vazir, Arial, sans-serif !important;
    font-weight: 700;
    position: relative;
}

.market-summary-price {
    font-size: 11px;
}

.marketMenu div {
    background-color: #FFF;
}

.menu-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-left: 1px solid #ccc;
}

.menu-slider div {
    margin-right: 10px;
    text-align: right;
}

.green-number {
    color: #00873c;
}

.green-border-bottom {
    border-bottom: 6px solid #00873c;
}

.red-number {
    color: #eb0f29;
}

.red-border-bottom {
    border-bottom: 6px solid #eb0f29;
}

.market-summary-row {
    width: 100%;
    margin: 0 auto;
}

.market-summary-item {
    padding-bottom: 14px;
}

.side-box {
    width: 20%;
}

.market-summary-vertical-line {
    border-left: 1px solid #ebebeb;
}

.market-summary-chart {
    position: absolute;
    right: 15px;
    top: 0;
}

.slick-list {
    margin-right: 20px;
    text-align: left;
}

@media (min-width: 0px) and (max-width: 990px) {
    .ad-box {
        display: none;
    }

    .navbar {
        /*background-color: #f5f5f5;*/
    }

    .market-summary-indices {
        width: 100% !important;
    }

    .extra-last-update, .extra-switch-btn {
        display: none;
    }

    .stock-box {
        padding: 10px;
    }

    .extra-sectors-dropdown, .extra-trends-slider, .live-table-title {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .ReactVirtualized__Table__headerColumn {
        background: none;
    }

    .live-chart-ranges {
        display: none;
    }

    .market-summary-vertical-line {
        padding: 0 10px;
    }

    .stock-summary {
        min-height: auto;
    }

    .stock-sr-chart {
        height: 300px !important;
    }

    .stock-sr-title {
        text-align: right !important;
    }

    .right-watchlist-content, .market-summary-chart {
        display: none;
    }

    .middle-box-content {
        width: 100% !important;
    }

    .slider-content {
        display: none;
    }

    .dl-image {
        width: 700px;
    }

    .payment-box {
        width: 100%;
    }
}

@media (min-width: 0px) and (max-width: 340px) {
    .dl-image {
        width: 300px;
    }
}

@media (min-width: 340px) and (max-width: 375px) {
    .dl-image {
        width: 340px;
    }
}

@media (min-width: 375px) and (max-width: 414px) {
    .dl-image {
        width: 375px;
    }
}

@media (min-width: 414px) and (max-width: 768px) {
    .dl-image {
        width: 414px;
    }
}

.slick-slider {
    /*top: 50%;*/
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transform: none !important;
    height: inherit;
    text-align: center;
    margin-right: 0;
    position: relative;
}

.live-circle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #37a836;
    animation-name: dotfade;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    margin-right: 3px;
}

@keyframes dotfade {
    0% {
        background-color: rgba(55, 168, 54, 0);
    }
    50% {
        background-color: #37a836;
    }
    100% {
        background-color: rgba(55, 168, 54, 0);
    }
}

.green-number {
    color: #00873c;
}

.red-number {
    color: #eb0f29;
}

.range-selectors {
    text-align: left;
    margin-top: 3px;
    font-size: 13px;
    padding-left: 0
}

.history-date-range-btn {
    padding: 5px 14px;
    cursor: pointer;
}

#js-plotly-tester {
    display: none
}

.ui.search.selection.dropdown > input.search, .ui.visible.selection.dropdown > .dropdown.icon, .ui.visible.selection.dropdown > .search.icon {
    padding: 8px 14px !important;
}

.each-nav-link {
    text-align: center;
}

.each-fake-nav {
    cursor: inherit;
}

#TopBar {
    display: none
}